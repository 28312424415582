import event from './event';
/*import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';*/

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [event]
    //items: [dashboard, pages, utilities, other]
};

export default menuItems;
