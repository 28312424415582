import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

import { ChakraProvider } from '@chakra-ui/react';

const App = () => {
    const storeData = useSelector((state) => state.customization);
    const customization = useSelector((state) => state.customization);
    const [myDisplay, setMyDisplay] = useState('none');
    const dispatch = useDispatch();

    useEffect(() => {
        if (storeData.showLoader == 0) {
            setMyDisplay('none');
        } else {
            setMyDisplay('flex');
        }
    }, [storeData.showLoader]);
    return (
        <ChakraProvider>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
            <div
                style={{
                    backgroundColor: 'rgba(191, 191, 191, 0.5)',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    position: 'fixed',
                    zIndex: 9999,
                    display: myDisplay
                }}
            ></div>
        </ChakraProvider>
    );
};

export default App;
