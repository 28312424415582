import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import 'bootstrap/dist/css/bootstrap.min.css';
//USER routing
const AddUser = Loadable(lazy(() => import('views/user/add')));
const IndexUser = Loadable(lazy(() => import('views/user')));

//Litige routing
const IndexLitige = Loadable(lazy(() => import('views/litige')));

//Pdv routing
const IndexPdv = Loadable(lazy(() => import('views/pdv/index')));
const AttributionPdv = Loadable(lazy(() => import('views/pdv/attribution')));

//Sales routing
const IndexSales = Loadable(lazy(() => import('views/sales/index')));

//Guest routing
const AddGuest = Loadable(lazy(() => import('views/guest/add')));
const IndexGuest = Loadable(lazy(() => import('views/guest/index')));

// event routing
const AddEvent = Loadable(lazy(() => import('views/event/add')));
const IndexEvent = Loadable(lazy(() => import('views/event/index')));
const IndexEnter = Loadable(lazy(() => import('views/event/enter')));

// prospect routing
const Prospect = Loadable(lazy(() => import('views/prospect/index')));
const AddProspect = Loadable(lazy(() => import('views/prospect/add')));

// call routing
const IndexCall = Loadable(lazy(() => import('views/call/index')));

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'guest',
            children: [
                {
                    path: '',
                    element: <IndexGuest />
                },
                {
                    path: 'modify/:uuid',
                    element: <AddGuest />
                },
                {
                    path: 'add',
                    element: <AddGuest />
                }
            ]
        },
        {
            path: 'prospect',
            children: [
                {
                    path: '',
                    element: <Prospect />
                },
                {
                    path: 'modify/:uuid',
                    element: <AddProspect />
                },
                {
                    path: 'add',
                    element: <AddProspect />
                }
            ]
        },
        {
            path: 'event',
            children: [
                {
                    path: '',
                    element: <IndexEvent />
                },
                {
                    path: 'enter',
                    element: <IndexEnter />
                },
                {
                    path: 'add',
                    element: <AddEvent />
                },
                {
                    path: 'update/:uuid',
                    element: <AddEvent />
                }
            ]
        },
        {
            path: 'pdv',
            children: [
                {
                    path: '',
                    element: <IndexPdv />
                },
                {
                    path: 'attribution',
                    element: <AttributionPdv />
                }
            ]
        },
        {
            path: 'litige',
            children: [
                {
                    path: '',
                    element: <IndexLitige />
                }
            ]
        },
        {
            path: 'user',
            children: [
                {
                    path: 'add',
                    element: <AddUser />
                },
                {
                    path: 'modify/:uuid',
                    element: <AddUser />
                },
                {
                    path: '',
                    element: <IndexUser />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'ventes',
            element: <IndexSales />
        },
        {
            path: 'call',
            element: <IndexCall />
        }
    ]
};

export default MainRoutes;
