import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconDashboard } from '@tabler/icons';
const icons = { IconDashboard };
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { requestAxios } from 'utils/common';
import { useNavigate } from 'react-router-dom';
import playstore from '../../../assets/images/playstore.jpg'

const menuItems = {
    items: [
        {
            id: 'event',
            title: 'Access Event',
            type: 'group',
            children: [
                /*{
                    id: 'default',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard/default',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'event',
                    title: 'Evenement',
                    type: 'item',
                    url: '/event',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'sales',
                    title: 'Suivi des ventes',
                    type: 'item',
                    url: '/ventes',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'litige',
                    title: 'Suivi des litiges',
                    type: 'item',
                    url: '/litige',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'user',
                    title: 'Utilisateurs',
                    type: 'item',
                    url: '/user',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'prospects',
                    title: 'Propects',
                    type: 'item',
                    url: '/prospect',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'enter',
                    title: 'Suivi des entrées',
                    type: 'item',
                    url: '/event/enter',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'event',
                    title: 'Evenement',
                    type: 'item',
                    url: '/event/add',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'prospect',
                    title: 'Mes prospects',
                    type: 'item',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'sales',
                    title: 'Suivi des ventes',
                    type: 'collapse',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    children: [
                        {
                            id: 'login3',
                            title: 'Login',
                            type: 'item',
                            url: '/pages/login/login3',
                            target: true
                        },
                        {
                            id: 'register3',
                            title: 'Register',
                            type: 'item',
                            url: '/pages/register/register3',
                            target: true
                        }
                    ]
                },
                {
                    id: 'litige',
                    title: 'Suivi des tickets litigieux',
                    type: 'item',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'accreditation',
                    title: 'Suivi des accreditations',
                    type: 'item',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'account',
                    title: 'Gestion des comptes',
                    type: 'item',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                },
                {
                    id: 'distribution',
                    title: 'Distribution',
                    type: 'item',
                    url: '/prospect/list',
                    icon: icons.IconDashboard,
                    breadcrumbs: false
                }*/
            ]
        }
    ]
    //items: [dashboard, pages, utilities, other]
};
const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    const [items, setItems] = useState({
        items: [
            {
                id: 'event',
                title: 'Access Event',
                type: 'group',
                children: []
            }
        ]
    });
    useEffect(() => {
        var user = localStorage.getItem('managerEvent');
        if (user == null || user == undefined) {
            navigate('/connexion');
        } else {
            init();
        }
    }, []);
    const init = async () => {
        var request = await requestAxios({}, 'manager/event/list-authorize', 'POST');
        console.log(request);
        if (request.status === 200) {
            var mx = {
                items: [
                    {
                        id: 'event',
                        title: 'Access Event',
                        type: 'group',
                        children: []
                    }
                ]
            };
            for (var i = 0; i < request.data.data.length; i++) {
                var pages = request.data.data[i].pages;
                if (i == 0) {
                    pages.forEach((x) => {
                        mx.items[0].children.push({
                            id: x.path,
                            title: x.name,
                            type: 'item',
                            url: x.path,
                            icon: icons.IconDashboard,
                            breadcrumbs: false
                        });
                    });
                }
            }
            setItems(mx);
            console.log(mx);
            setList(request.data.data);
        }
    };
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuCard list={list} />
                    <MenuList menuItems={items} />
                 <div style={{height:'60px',width:'100%',display:'flex',justifyContent:"center"}}>
                    <img src={playstore} alt="playstore" style={{height:'100%',width:'90px',border:"2px solid white"}}/>
                 </div>
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuCard list={list} />
                    <MenuList menuItems={items} />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} style={{ zIndex: 9 }}>
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
