// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| EVENT MENU ITEMS ||============================== //

const event = {
    id: 'event',
    title: 'Access Event',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'event',
            title: 'Evenement',
            type: 'item',
            url: '/event',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'sales',
            title: 'Suivi des ventes',
            type: 'item',
            url: '/ventes',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'litige',
            title: 'Suivi des litiges',
            type: 'item',
            url: '/litige',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: 'Utilisateurs',
            type: 'item',
            url: '/user',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'prospects',
            title: 'Propects',
            type: 'item',
            url: '/prospect',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'enter',
            title: 'Suivi des entrées',
            type: 'item',
            url: '/event/enter',
            icon: icons.IconDashboard,
            breadcrumbs: false
        } /*,
        {
            id: 'event',
            title: 'Evenement',
            type: 'item',
            url: '/event/add',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'prospect',
            title: 'Mes prospects',
            type: 'item',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'sales',
            title: 'Suivi des ventes',
            type: 'collapse',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            children: [
                {
                    id: 'login3',
                    title: 'Login',
                    type: 'item',
                    url: '/pages/login/login3',
                    target: true
                },
                {
                    id: 'register3',
                    title: 'Register',
                    type: 'item',
                    url: '/pages/register/register3',
                    target: true
                }
            ]
        },
        {
            id: 'litige',
            title: 'Suivi des tickets litigieux',
            type: 'item',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'accreditation',
            title: 'Suivi des accreditations',
            type: 'item',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'account',
            title: 'Gestion des comptes',
            type: 'item',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'distribution',
            title: 'Distribution',
            type: 'item',
            url: '/prospect/list',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }*/
    ]
};

export default event;
